import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';
import { isVoid } from '../../../core/utils/isVoid';

const MapTurtle = ({ config, active }) => {
    const turtle = useTurtle(config, active);
    const { setError } = turtle;

    const overlaysRef = useRef(null);
    const mapRef = useRef(null);

    const [map, setMap] = useState(null);

    const initMaps = useCallback(() => {
        // create map
        setMap(
            new window.google.maps.Map(mapRef.current, {
                zoom: 13,
                disableDefaultUI: true,
            })
        );
    }, []);

    // load the Google Maps script if it doesn't exist
    useEffect(() => {
        const onLoad = () => initMaps();
        const onError = error => setError(error);
        if (!window.google) {
            const script = document.createElement(`script`);
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
            document.head.append(script);
            script.addEventListener(`load`, onLoad);
            script.addEventListener(`error`, onError);
            return () => {
                script.removeEventListener(`load`, onLoad);
                script.removeEventListener(`error`, onError);
            };
        } else {
            onLoad();
        }
    }, [setError, initMaps]);

    // set map based on config
    useEffect(() => {
        if (active && map !== null) {
            let position;
            if (config && config.location) {
                position = {
                    lat: config.location.latitude,
                    lng: config.location.longitude,
                }
            } else {
                position = {
                    lat: 51.2057642,
                    lng: 4.4290947
                }
            }
           
            // center map
            map.setCenter(position);

            // clear previous overlays
            if (!isVoid(overlaysRef.current)) {
                for (const overlay of overlaysRef.current) {
                    overlay.setMap(null);
                }
            }

            // add new overlays
            const marker = new window.google.maps.Marker({
                position: position,
                map: map,
            });
            const traffic = new window.google.maps.TrafficLayer({
                map: map,
            });
            overlaysRef.current = [marker, traffic];

            // first set zoom
            map.setZoom(14);

            // zoom out after timeout
            const id = setTimeout(() => {
                map.setZoom(12);
            }, 2000);
            return () => clearTimeout(id);
        }
    }, [map, config, active]);

    return (
        <BaseTurtle turtle={turtle}>
            <div className="turtle__map" ref={mapRef} />
        </BaseTurtle>
    );
};

export default MapTurtle;
