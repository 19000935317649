import React from 'react';
import { useTurtle } from '../../core/hooks/useTurtle';
import Header from './header/Header';

const UnknownTurtle = ({ config, active }) => {
    const turtle = useTurtle(config, active);

    return (
        <div className={turtle.className}>
            <Header title={`Unknown turtle: ${config.type}`} />
        </div>
    );
};

export default UnknownTurtle;
