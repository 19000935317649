import React from 'react';
import Turtle from './Turtle';
import { useIndex } from '../../core/hooks/useIndex';

const TurtleHolder = ({ turtles, gridArea, active, ...props }) => {
    const index = useIndex(turtles, active);

    return (
        <li className="turtle-container" {...props}>
            {turtles.map((turtle, i) => (
                <Turtle
                    key={`${gridArea}_${i}`}
                    config={turtle}
                    active={i === index && active}
                />
            ))}
        </li>
    );
};

export default TurtleHolder;
