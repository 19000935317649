import React from 'react';
import { useDateTime } from '../../core/hooks/useDateTime';

const Clock = () => {
    const [time] = useDateTime(false);

    return <span className="clock">{time}</span>;
};

export default Clock;
