import React, { useEffect, useState } from 'react';
import App from '../../app/App';
import Dashboard from '../../dashboard/Dashboard';
import { Localization } from '../../../core/localization';

// this component/page is to be used in an iFrame
const PreviewPage = () => {
    const [config, setConfig] = useState();

    useEffect(() => {
        // let parent know we are ready to receive initial data
        if (window.top) {
            window.top.postMessage('initialized', '*');
        }

        // listen to parent sending us data
        window.onmessage = event => {
            if (event.data.general?.lang) {
                Localization.setLocale(event.data.general?.lang);
            }

            setConfig(event.data);
        };
    }, []);

    if (!config) {
        return null;
    }

    return (
        <App config={config}>
            <Dashboard config={config} />
        </App>
    );
};

export default PreviewPage;
