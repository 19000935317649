import { isVoid } from '../../../core/utils/isVoid';
import React from 'react';

const Error = ({ error }) => {
    if (isVoid(error)) {
        return null;
    }

    return <p className="turtle-error">{error}</p>;
};

export default Error;
