import { useCallback } from 'react';
import mqtt from 'mqtt';
import * as Sentry from '@sentry/browser';

// small promise wrapper
const getClient = (url, options) => {
    return new Promise(function(resolve, reject) {
        const client = mqtt.connect(url, options);
        client.on('connect', () => {
            client.removeAllListeners();
            resolve(client);
        });
        client.on('error', e => {
            client.removeAllListeners();
            client.end();
            // log to Sentry
            console.log(e);
            Sentry.captureException(e);
            reject(e);
        });
    });
};

/*
 * Since we don't listen to any MQTT events, for now we'll connect only when necessary while not maintaining the connection
 */
const useMqtt = host => {
    return useCallback(
        message => {
            const send = async message => {
                try {
                    const client = await getClient(
                        process.env.REACT_APP_MQTT_HOST,
                        {
                            clientId: `${host}-web`,
                        }
                    );

                    client.publish(host, message, {}, () => {
                        client.end();
                    });
                } catch (ignore) {}
            };
            send(message);
        },
        [host]
    );
};

export default useMqtt;
