import React from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';
import { t } from 'core/localization';
import Icon from '../Icon';

const WeatherTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);
    const { data } = turtle;

    return (
        <BaseTurtle turtle={turtle}>
            {data && (
                <ul className="weather-blocks">
                    {data.map(item => (
                        <li key={`${item.type}`} className="weather-block">
                            <Icon
                                className="weather-block__icon"
                                name={item.type}
                            />
                            <span className="weather-block__value">
                                {item.value}
                            </span>
                            <span className="weather-block__label">
                                {t(`weather.${item.type}`)} ({item.unit})
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </BaseTurtle>
    );
};

export default WeatherTurtle;
