import moment from 'moment';
import React, {
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import Icon from '../Icon';

const Item = ({ label, time, badges, delay, maxOffset, showIcons = true }) => {
    const ref = useRef();

    const [opacity, setOpacity] = useState(1);

    // hide items that overflow
    useLayoutEffect(() => {
        if (maxOffset && ref.current) {
            if (
                ref.current?.offsetTop + ref.current?.clientHeight >
                maxOffset
            ) {
                setOpacity(0);
            }
        }
    }, [maxOffset]);

    return (
        <li className="schedule-item" ref={ref} style={{ opacity: opacity }}>
            <ul className="schedule-item__badges">
                {badges
                    .filter(badge => showIcons || !badge.icon) // only show icon badge if allowed
                    .map(badge => (
                        <li
                            key={`${label}_${time}_${badge.label}`}
                            style={{
                                backgroundColor:
                                    badge.background ||
                                    (badge.icon ? '#ebebeb' : '#373737'),
                                color: badge.color || '#FFFFFF',
                                fill: badge.color || '#000',
                            }}>
                            {badge.icon ? (
                                <Icon name={badge.icon} />
                            ) : (
                                badge.label
                            )}
                        </li>
                    ))}
            </ul>
            <span className="schedule-item__label">{label}</span>
            <span className="schedule-item__time">
                {moment(time).format('HH:mm')}
            </span>
            <span className="schedule-item__delay">
                {delay && delay > 0 ? `+${delay}'` : ''}
            </span>
        </li>
    );
};

export default Item;
