import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '../../storage/LocalStorage';
import { ScreenStatus } from './constants';
import { Localization } from '../../localization';

// normally we should use a router, but since the scope of our app (no browsing possible) this is just fine
const uid = window.location.pathname.slice(1);

const INITIAL_STATE = {
    status: LocalStorage.hasConfig(uid)
        ? ScreenStatus.CACHED
        : ScreenStatus.IDLE,
    config: LocalStorage.getConfig(uid),
    uid: uid,
    timestamp: 0,
};

const screenSlice = createSlice({
    name: 'connection',
    initialState: INITIAL_STATE,
    reducers: {
        setConfigLoading(state) {
            // only set as loading when there are no turtles shown yet
            if (
                state.status !== ScreenStatus.LOADED &&
                state.status !== ScreenStatus.CACHED
            ) {
                state.status = ScreenStatus.LOADING;
            }
        },
        setConfigData(state, action) {
            const config = action.payload;
            // save to storage
            LocalStorage.saveConfig(state.uid, config);
            // set locale
            Localization.setLocale(config.general.lang);
            // set state
            state.status = ScreenStatus.LOADED;
            state.config = config;
            state.timestamp = new Date().getTime();
            state.error = null;
        },
        setConfigError(state, action) {
            state.status = ScreenStatus.ERROR;
            state.error = action.payload;
        },
        setScreenStatus(state, action) {
            state.status = action.payload;
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = screenSlice;
// Extract and export each action creator by name
export const {
    setConfigLoading,
    setConfigData,
    setConfigError,
    setScreenStatus,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
