import { useState, useEffect, useCallback, useMemo } from 'react';
import { client } from '../networking/client';
import { CancelToken, isCancel } from 'axios';
import { isEmptyText } from '../utils/isEmptyText';
import { isNetworkError } from '../utils/isNetworkError';
import { useDispatch } from 'react-redux';
import { reportNetworkError } from '../modules/connection/actions';
import * as Sentry from '@sentry/browser';
import { isVoid } from '../utils/isVoid';

export const useTurtle = (config, isActive) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const value = useMemo(
        () => ({
            data: data,
            className: `turtle turtle--${config.type} ${
                isActive ? 'turtle--active' : ''
            }`,
            error: error,
            setError: setError,
        }),
        [data, config.type, isActive, error, setError]
    );

    const loadData = useCallback(
        url => {
            if (url) {
                const source = CancelToken.source();
                client
                    .get(url, {
                        cancelToken: source.token,
                    })
                    .then(data => {
                        setData(data.data);
                        if (!isVoid(error)) {
                            setError(null);
                        }
                    })
                    .catch(error => {
                        if (!isCancel(error)) {
                            if (isNetworkError(error)) {
                                // we're offline, make sure everyone knows!
                                dispatch(reportNetworkError());
                            } else {
                                // log to Sentry
                                Sentry.captureException(error);
                                // show in view
                                setError(error);
                            }
                        }
                    });

                return () => source.cancel();
            }
        },
        [dispatch, error]
    );

    useEffect(() => {
        if (isActive && !isEmptyText(config.url)) {
            let id;
            let cancelToken;
            const next = () => {
                cancelToken = loadData(config.url);
                id = setTimeout(next, (config.expires || 10) * 1000);
            };
            // call function
            next();
            return () => {
                cancelToken && cancelToken();
                id && clearTimeout(id);
            };
        }
    }, [loadData, config, isActive]);

    return value;
};
