import React from 'react';
import Logo from 'assets/images/logo-lg.svg';

const SplashLoadingScreen = () => {
    return (
        <div className="splash">
            <img src={Logo} alt="FlatTurtle" />
            <span className="splash__text">Loading</span>
        </div>
    );
};

export default SplashLoadingScreen;
