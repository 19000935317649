import React from 'react';
import ScheduleTurtle from './schedule/ScheduleTurtle';
import EmbedTurtle from './embed/EmbedTurtle';
import ImageTurtle from './image/ImageTurtle';
import TextTurtle from './text/TextTurtle';
import ClockTurtle from './clock/ClockTurtle';
import VideoTurtle from './video/VideoTurtle';
import UnknownTurtle from './UnknownTurtle';
import MapTurtle from './map/MapTurtle';
import ReservationsTurtle from './reservations/ReservationsTurtle';
import WeatherTurtle from './weather/WeatherTurtle';
import AvailabilityTurtle from './availability/AvailabilityTurtle';
import SignageTurtle from './signage/SignageTurtle';
import NewsTurtle from './news/NewsTurtle';

const Turtle = props => {
    // this small component acts as a mapping between turtle and template
    const { config } = props;

    switch (config.type) {
        case 'text':
            return <TextTurtle {...props} />;

        case 'schedule':
            return <ScheduleTurtle {...props} />;

        case 'availability':
            return <AvailabilityTurtle {...props} />;

        case 'iframe':
            return <EmbedTurtle {...props} />;

        case 'video':
            return <VideoTurtle {...props} />;

        case 'image':
            return <ImageTurtle {...props} />;

        case 'clock':
            return <ClockTurtle {...props} />;

        case 'map':
            return <MapTurtle {...props} />;

        case 'reservations':
            return <ReservationsTurtle {...props} />;

        case 'signage':
            return <SignageTurtle {...props} />;

        case 'news':
            return <NewsTurtle {...props} />;

        case 'weather':
            return <WeatherTurtle {...props} />;

        default:
            return <UnknownTurtle {...props} />;
    }
};

export default Turtle;
