import React from 'react';
import TurtleHolder from './TurtleHolder';

const TurtleGrid = ({ className, screen, active = true }) => {
    return (
        <ul
            className={`turtle-grid ${
                active ? 'turtle-grid--active' : ''
            } ${className}`}
            style={{
                gridTemplateAreas: screen.template
                    .map(str => `"${str}"`)
                    .join(' '),
            }}>
            {Object.keys(screen.turtles).map(key => (
                <TurtleHolder
                    key={key}
                    gridArea={key}
                    turtles={screen.turtles[key]}
                    style={{ gridArea: key }}
                    active={active}
                />
            ))}
        </ul>
    );
};

export default TurtleGrid;
