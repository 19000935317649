import React from 'react';
import { useDateTime } from '../../../core/hooks/useDateTime';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';

const ClockTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);
    const [time, date] = useDateTime(true);

    return (
        <BaseTurtle
            turtle={turtle}
            style={{ textAlign: config.style?.align || 'center' }}>
            <span className="turtle__date">{date}</span>
            <span className="turtle__time">{time}</span>
        </BaseTurtle>
    );
};

export default ClockTurtle;
