import React from 'react';
import ReactDOM from 'react-dom';
import ScreenPage from './components/pages/screen/ScreenPage';
import './style/app.scss';
import { rootReducers } from './core/modules';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { configureStore } from '@reduxjs/toolkit';
import { initLocalizations } from './core/localization';
import PreviewPage from './components/pages/preview/PreviewPage';

const store = configureStore({
    reducer: rootReducers,
});

// init Sentry
Sentry.init({ dsn: process.env.REACT_APP_SENTRY_KEY });

const showPreview = window.location.pathname === '/preview';

initLocalizations();

ReactDOM.render(
    <Provider store={store}>
        {showPreview ? <PreviewPage /> : <ScreenPage />}
    </Provider>,
    document.getElementById('root')
);
