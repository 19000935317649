import { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ScreenStatus } from '../modules/screen/constants';
import { setScreenStatus } from '../modules/screen/reducer';
import useMqtt from './useMqtt';

export const useJobs = (jobs, hostname) => {
    const dispatch = useDispatch();

    const sendMessage = useMqtt(hostname);

    const handleCommand = useCallback(
        command => {
            // send command to MQTT
            sendMessage(command);
            // handle specific command
            switch (command) {
                case 'display_off':
                    dispatch(setScreenStatus(ScreenStatus.SLEEPING));
                    break;

                case 'display_on':
                    dispatch(setScreenStatus(ScreenStatus.IDLE));
                    break;

                default:
                    break;
            }
        },
        [dispatch, sendMessage]
    );

    // create a timeout for every job with a timestamp in the future
    useEffect(() => {
        const timeouts = jobs
            .filter(job => moment().isBefore(moment(job.timestamp)))
            .map(job =>
                setTimeout(
                    () => handleCommand(job.command),
                    moment(job.timestamp).valueOf() - moment().valueOf()
                )
            );

        return () => {
            for (const timeout of timeouts) {
                clearTimeout(timeout);
            }
        };
    }, [jobs, dispatch, handleCommand]);

    return null;
};
