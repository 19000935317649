import { useEffect, useState } from 'react';
import { isVoid } from '../utils/isVoid';

export const useIndex = (items, active = true) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (items.length > 1) {
            if (active) {
                const newIndex = index + 1 === items.length ? 0 : index + 1;

                if (isVoid(items[index].duration)) {
                    throw new Error('Missing duration property');
                }
                const id = setTimeout(
                    () => setIndex(newIndex),
                    items[index].duration * 1000
                );
                return () => clearTimeout(id);
            } else {
                // reset index if not active
                setIndex(0);
            }
        }
    }, [items, active, index]);

    return index;
};
