import React, { useEffect, useState } from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';
import Header from '../header/Header';
import Item from './Item';
import moment from 'moment';
import useHeight from '../../../core/hooks/useHeight';

const ScheduleTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);
    const [ref, height] = useHeight();
    const { data } = turtle;

    // filter data
    const [filtered, setFiltered] = useState(null);
    const [timestamp, setTimestamp] = useState(moment.utc());

    useEffect(() => {
        // check every few seconds if data is still valid
        if (data) {
            let id;
            const next = () => {
                setTimestamp(moment.utc());
                setFiltered(
                    data.filter(item =>
                        moment(item.time)
                            .add(item.delay, 'minutes')
                            .add(1, 'minutes') // keep it visible for a minute after "expiring"
                            .isAfter(moment())
                    )
                );
                id = setTimeout(next, 5000);
            };
            // call function
            next();
            return () => clearTimeout(id);
        }
    }, [data]);

    return (
        <BaseTurtle turtle={turtle}>
            <Header {...config} />
            {filtered && (
                <ul className="schedule" ref={ref}>
                    {filtered.map(item => (
                        <Item
                            key={`${item.label}_${item.time}_${timestamp}`}
                            {...item}
                            maxOffset={height}
                            showIcons={config.show_icons}
                        />
                    ))}
                </ul>
            )}
        </BaseTurtle>
    );
};

export default ScheduleTurtle;
