import React from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';

const TextTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);

    return (
        <BaseTurtle
            turtle={turtle}
            style={{ textAlign: config.style?.align || 'center' }}>
            <p>{config.content}</p>
        </BaseTurtle>
    );
};

export default TextTurtle;
