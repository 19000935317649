import React, { useCallback, useEffect } from 'react';
import { getScreenConfig } from '../../../core/modules/screen/actions';
import AppStatus from '../../app/AppStatus';
import { useJobs } from '../../../core/hooks/useJobs';
import { useDispatch, useSelector } from 'react-redux';
import { ScreenStatus } from '../../../core/modules/screen/constants';
import { useConnection } from '../../../core/hooks/useConnection';
import App from '../../app/App';

const ScreenPage = () => {
    const dispatch = useDispatch();

    const { status, config, error } = useSelector(state => state.screen);

    // use jobs
    useJobs(config?.jobs || [], config?.general?.hostname || '');

    // use connection watcher
    useConnection();

    // fetch the config data
    const fetchInitialData = useCallback(() => {
        // fetch config
        dispatch(getScreenConfig());
    }, [dispatch]);

    // fetch config data on start or when status changes and is IDLE
    useEffect(() => {
        if (status === ScreenStatus.IDLE || status === ScreenStatus.CACHED) {
            fetchInitialData();
        }
    }, [fetchInitialData, status]);

    return (
        <App config={config}>
            <AppStatus status={status} config={config} error={error} />
        </App>
    );
};

export default ScreenPage;
