import React from 'react';
import Footer from '../footer/Footer';
import {
    getRotation,
} from '../../core/modules/screen/selectors';
import Clock from '../clock/Clock';
import ConnectionStatusMessage from '../error/ConnectionStatusMessage';
import Main from '../main/Main';

const Dashboard = ({ config }) => {
    return (
        <div className={`dashboard dashboard--rotate-${getRotation(config)}`}>
            <Main config={config} />

            {config.interface.clock && <Clock />}

            <ConnectionStatusMessage />

            <Footer config={config} />
        </div>
    );
};

export default Dashboard;
