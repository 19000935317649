import React from 'react';
import Icon from '../Icon';
import { ReactComponent as Walking } from 'assets/icons/walking.svg';

// eslint-disable-next-line camelcase
const Header = ({ title, icon, logo, walking_time }) => {
    return (
        <header className="turtle-header">
            {icon && (
                <div
                    className="turtle-header__icon"
                    style={{
                        backgroundColor: icon.background,
                        fill: icon.color,
                    }}>
                    <Icon name={icon.type} />
                </div>
            )}
            <h2 className="turtle-header__title">{title}</h2>

            {// eslint-disable-next-line camelcase
            walking_time && walking_time > 0 ? (
                <span className="turtle-header__walking-time">
                    <Walking />
                    {/* eslint-disable-next-line camelcase */}
                    <span>{walking_time}'</span>
                </span>
            ) : null}

            <div className="turtle-header__logo">
                {logo && <img src={logo} alt="" />}
            </div>
        </header>
    );
};

export default Header;
