import React from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';
import Header from '../header/Header';
import moment from 'moment';
import { isEmptyText } from '../../../core/utils/isEmptyText';
import Icon from '../Icon';

const NewsTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);
    const { data } = turtle;

    return (
        <BaseTurtle turtle={turtle}>
            <Header {...config} />
            {data && (
                <ul className="news-blocks">
                    {data.map(item => (
                        <li
                            className="news-block"
                            key={`${item.source}_${item.time}`}>
                            {isEmptyText(item.image) ? (
                                <span className="news-block__image news-block__image--placeholder" />
                            ) : (
                                <img
                                    className="news-block__image"
                                    src={item.image}
                                    alt=""
                                />
                            )}
                            <div className="news-block__content">
                                <h2 className="news-block__title">{item.title}</h2>
                                <div className="news-block__meta">
                                    <span className="news-block__source">{item.source}</span>
                                    <span className="news-block__time"><Icon name="clock" />{moment(item.time).format('HH:mm')}</span>
                                </div>
                                <p className="news-block__description">{item.short}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </BaseTurtle>
    );
};

export default NewsTurtle;
