import React from 'react';

import { ReactComponent as Airport } from 'assets/icons/airport.svg';
import { ReactComponent as Bike } from 'assets/icons/bike.svg';
import { ReactComponent as Bus } from 'assets/icons/bus.svg';
import { ReactComponent as Train } from 'assets/icons/train.svg';
import { ReactComponent as Tram } from 'assets/icons/tram.svg';
import { ReactComponent as Temperature } from 'assets/icons/temperature.svg';
import { ReactComponent as Wind } from 'assets/icons/wind.svg';
import { ReactComponent as Humidity } from 'assets/icons/humidity.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';

// for now this non-dynamic solution is good enough
const SvgIcon = props => {
    return <props.icon style={props.style} {...props} />;
};

const Icon = ({ name, fill, ...props }) => {
    let svg;

    switch (name) {
        case 'airport':
            svg = Airport;
            break;

        case 'train':
            svg = Train;
            break;

        case 'bike':
            svg = Bike;
            break;

        case 'bus':
            svg = Bus;
            break;

        case 'tram':
            svg = Tram;
            break;

        case 'temperature':
            svg = Temperature;
            break;

        case 'wind':
            svg = Wind;
            break;

        case 'humidity':
            svg = Humidity;
            break;

        case 'arrow':
            svg = Arrow;
            break;

        case 'clock':
            svg = Clock;
            break;

        default:
            return null;
    }

    return <SvgIcon icon={svg} style={{ fill: fill }} {...props} />;
};

export default Icon;
