import { useEffect, useState } from 'react';
import moment from 'moment';

// we could also use a memoized {time: true, date: true} config, but since we don't need that let's not overengineer it
export const useDateTime = useDate => {
    const [time, setTime] = useState(moment().format('HH:mm'));
    const [date, setDate] = useState(moment().format('ddd DD/MM'));

    useEffect(() => {
        const id = setInterval(() => {
            setTime(moment().format('HH:mm'));
            if (useDate) {
                setDate(moment().format('ddd DD/MM'));
            }
        }, 1000);
        return () => clearInterval(id);
    }, [useDate]);

    return useDate ? [time, date] : [time];
};
