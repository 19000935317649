import React from 'react';
import Dashboard from '../dashboard/Dashboard';
import SplashLoadingScreen from '../splash/SplashLoadingScreen';
import { ScreenStatus } from '../../core/modules/screen/constants';
import ErrorScreen from '../error/ErrorSceen';

const AppStatus = ({ status, config, error }) => {
    // small mapping component
    switch (status) {
        case ScreenStatus.LOADED:
        case ScreenStatus.CACHED:
            return <Dashboard config={config} />;

        case ScreenStatus.SLEEPING:
            return null;

        case ScreenStatus.ERROR:
            return <ErrorScreen error={error} />;

        case ScreenStatus.LOADING:
        default:
            return <SplashLoadingScreen />;
    }
};

export default AppStatus;
