import React from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';

const VideoTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);

    return (
        <BaseTurtle turtle={turtle}>
            {active && (
                <video
                    src={config.content}
                    autoPlay={true}
                    muted={true}
                    style={{
                        objectFit: config.style?.size || 'cover',
                        objectPosition: config.style?.align || 'center',
                    }}
                />
            )}
        </BaseTurtle>
    );
};

export default VideoTurtle;
