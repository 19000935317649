import { client } from '../../networking/client';
import { setConfigData, setConfigError, setConfigLoading } from './reducer';
import { isNetworkError } from '../../utils/isNetworkError';
import { reportNetworkError } from '../connection/actions';
import * as Sentry from '@sentry/browser';

export const getScreenConfig = () => (dispatch, getState) => {
    // mark loading
    dispatch(setConfigLoading());

    return client
        .get(
            `${process.env.REACT_APP_API_BASE_URL}?uid=${getState().screen.uid}`
        )
        .then(response => dispatch(setConfigData(response.data)))
        .catch(error => {
            if (isNetworkError(error)) {
                // mark as no connection
                dispatch(reportNetworkError());
            } else {
                // log to Sentry
                Sentry.captureException(error);
            }
            dispatch(setConfigError(String(error)));
        });
};
