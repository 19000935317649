import React from 'react';
import { useSelector } from 'react-redux';
import { ConnectionStatus } from '../../core/modules/connection/constants';
import { t } from 'core/localization';

import { ReactComponent as LogoAlert } from 'assets/images/logo-alert.svg';

const ConnectionStatusMessage = () => {
    const status = useSelector(state => state.connection.status);

    return status === ConnectionStatus.OFFLINE ? (
        <div className="error error--connection">
            <LogoAlert className="error__icon" />
            <div className="error__content">
                <h2 className="error__title">
                    {t('error.no_connection.title')}
                </h2>
                <p className="error__description">
                    {t('error.no_connection.description')}
                </p>
            </div>
        </div>
    ) : null;
};

export default ConnectionStatusMessage;
