import React from 'react';
import Error from './error/Error';

const BaseTurtle = ({ turtle, children, ...props }) => {
    // we could use useTurtle here too and pass it on, but we want the turtle itself to take care of this, to avoid re-rendering (e.g. Google Maps)
    return (
        <div className={turtle.className} {...props}>
            <Error error={turtle.error} />
            {children}
        </div>
    );
};

export default BaseTurtle;
