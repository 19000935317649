import React, { useEffect, useState } from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';
import Header from '../header/Header';
import moment from 'moment';
import { t } from '../../../core/localization';
import ReservationInfo from './ReservationInfo';
import ReservationList from './ReservationList';

const ReservationsTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);
    const { data } = turtle;

    // filter data
    const [current, setCurrent] = useState(null);
    const [schedule, setSchedule] = useState([]);

    useEffect(() => {
        // check every few seconds if data is still valid
        if (data) {
            let id;
            const next = () => {
                // filter
                const filtered = data.filter(item =>
                    moment(item.to).isAfter(moment())
                );
                // sort array just to be sure
                filtered.sort((a, b) =>
                    moment(a.from).isBefore(moment(b.from)) ? -1 : 1
                );
                // set items
                if (filtered.length > 0) {
                    // set current
                    setCurrent(filtered.splice(0, 1)[0]);
                    // set schedule
                    setSchedule(filtered);
                } else {
                    setCurrent(null);
                    setSchedule([]);
                }

                id = setTimeout(next, 5000);
            };
            // call function
            next();
            return () => clearTimeout(id);
        }
    }, [data]);

    return (
        <BaseTurtle turtle={turtle}>
            <div className="reservation__current">
                <Header title={t('reservations.next.title')} />
                <ReservationInfo current={current} />
            </div>
            <div className="reservation__schedule">
                <Header title={t('reservations.schedule.title')} />
                <ReservationList list={schedule} />
            </div>
        </BaseTurtle>
    );
};

export default ReservationsTurtle;
