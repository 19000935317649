import React from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';
import Header from '../header/Header';
import Pie from './chart/Pie';

const AvailabilityTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);
    const { data } = turtle;

    return (
        <BaseTurtle turtle={turtle}>
            <Header {...config} />
            {data && (
                <>
                    <ul className="availability-list">
                        {data.map(item => (
                            <li className="availability-item" key={item.label}>
                                <div className="availability-item__chart">
                                    <Pie
                                        capacity={item.capacity}
                                        available={item.available}
                                        colors={['#25C752', '#E2F8E8']}
                                    />
                                    <span className="availability-item__number">
                                        {item.capacity ? (
                                            <>
                                                {`${item.available}`}
                                                <span className="availability-item__capacity">
                                                    /{`${item.capacity}`}
                                                </span>
                                            </>
                                        ) : (
                                            `${item.available}`
                                        )}
                                    </span>
                                </div>
                                <span className="availability-item__label">
                                    {item.label}
                                </span>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </BaseTurtle>
    );
};

export default AvailabilityTurtle;
