export const getPrimaryColor = config => {
    return config.interface.primary_color;
};

export const getTextColor = config => {
    return config.interface.text_color;
};

export const getRotation = config => {
    return config.interface.rotation;
};

export const getFooterScreens = config => {
    return config.interface.footer;
};

export const getMainScreens = config => {
    return config.interface.main;
};
