export default {
    'app.name': 'MyTurtle v3',

    'error.no_connection.title': 'No connection',
    'error.no_connection.description': 'Trying to reconnect',

    'reservations.next.title': 'Next up',
    'reservations.next.empty': 'No reservation',
    'reservations.next.announce': 'With: ',
    'reservations.schedule.title': 'Upcoming',
    'reservations.schedule.empty': 'No upcoming reservations today',

    'weather.temperature': 'Temperature',
    'weather.wind': 'Wind speed',
    'weather.humidity': 'Humidity',
};
