import React from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';

const EmbedTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);

    return (
        <BaseTurtle turtle={turtle}>
            {active && (
                <iframe
                    src={config.content}
                    title="embed"
                    sandbox="allow-scripts allow-same-origin" // basically no sandbox... (remove in future?)
                />
            )}
        </BaseTurtle>
    );
};

export default EmbedTurtle;
