import { createSlice } from '@reduxjs/toolkit';
import { ConnectionStatus } from './constants';

const INITIAL_STATE = {
    status: ConnectionStatus.ONLINE,
};

const connectionSlice = createSlice({
    name: 'connection',
    initialState: INITIAL_STATE,
    reducers: {
        setConnectionStatus(state, action) {
            state.status = action.payload;
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = connectionSlice;
// Extract and export each action creator by name
export const { setConnectionStatus } = actions;
// Export the reducer, either as a default or named export
export default reducer;
