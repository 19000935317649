import React from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';
import Header from '../header/Header';
import Icon from '../Icon';
import { isEmptyText } from '../../../core/utils/isEmptyText';

const SignageTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);
    const { data } = turtle;

    return (
        <BaseTurtle turtle={turtle}>
            <Header {...config} />
            {data && (
                <>
                    <ul className="signage-list">
                        {data.map(item => (
                            <li className="signage-item" key={item.label}>
                                <div className="signage-item__arrows">
                                    {item.arrows.map(arrow => (
                                        <div
                                            key={`${item.label}-arrow-${arrow}`}
                                            className={`arrow arrow--${arrow} arrow--${
                                                item.arrows.length > 1
                                                    ? 'multi'
                                                    : 'single'
                                            }`}>
                                            <Icon name="arrow" />
                                        </div>
                                    ))}
                                </div>
                                <span className="signage-item__label">
                                    {item.label}
                                </span>
                                <ul className="signage-item__entities">
                                    {item.entities.map(entity => (
                                        <li
                                            className="entity"
                                            key={`${item.label}-${entity.name}`}>
                                            {isEmptyText(entity.logo) ? (
                                                <span className="entity__logo entity__logo--placeholder" />
                                            ) : (
                                                <img
                                                    className="entity__logo"
                                                    src={entity.logo}
                                                    alt=""
                                                />
                                            )}
                                            <span className="entity__name">
                                                {entity.name}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </BaseTurtle>
    );
};

export default SignageTurtle;
