import { getMainScreens } from '../../core/modules/screen/selectors';
import { useIndex } from '../../core/hooks/useIndex';
import TurtleGrid from '../turtle/TurtleGrid';
import React from 'react';

const Main = ({ config }) => {
    const main = getMainScreens(config);
    const mainIndex = useIndex(main);

    return (
        <div className="dashboard__turtles-container">
            {main.map((screen, i) => (
                <TurtleGrid
                    key={`grid_${i}`}
                    className="dashboard__turtle-grid"
                    active={i === mainIndex}
                    screen={screen}
                />
            ))}
        </div>
    );
};

export default Main;
