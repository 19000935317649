import React from 'react';
import { useTurtle } from '../../../core/hooks/useTurtle';
import BaseTurtle from '../BaseTurtle';

const ImageTurtle = ({ config, active, ...props }) => {
    const turtle = useTurtle(config, active);

    return (
        <BaseTurtle turtle={turtle}>
            <img
                src={config.content}
                style={{
                    objectFit: config.style?.size || 'contain',
                    objectPosition: config.style?.align || 'center',
                }}
                alt=""
            />
        </BaseTurtle>
    );
};

export default ImageTurtle;
