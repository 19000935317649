import React from 'react';

const getStrokeDashArray = percent => {
    return `${percent} ${100 - percent}`;
};

const getStrokeDashOffset = percent => {
    return 25 + percent; // move 25 to position at top + add percent to move it again so lighter color starts first
};

const Pie = ({ capacity, available, colors }) => {
    // for easier positioning, we draw the non-available part instead of the available one

    const percent = (available / (capacity || available)) * 100;

    return (
        <svg
            width="100%"
            height="100%"
            viewBox={'0 0 40 40'}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1">
            <circle className="donut-hole" cx="20" cy="20" r="16" fill="#fff" />

            <circle
                className="donut-ring"
                cx="20"
                cy="20"
                r="16"
                fill="transparent"
                stroke={colors[1]}
                strokeWidth="4"
            />

            <circle
                className="donut-segment"
                cx="20"
                cy="20"
                r="16"
                fill="transparent"
                strokeDasharray={getStrokeDashArray(percent)}
                strokeDashoffset={getStrokeDashOffset(percent)}
                stroke={colors[0]}
                strokeWidth="4"
            />
        </svg>
    );
};

export default Pie;
