// Languages
import en from './lang/en';
import nl from './lang/nl';
import fr from './lang/fr';

// Settings Language
export const initLocalizations = () => {
    Localization.init({
        dictionary: {
            en: en,
            nl: nl,
            fr: fr,
        },
        currentLocale: process.env.REACT_APP_BASE_LANGUAGE,
        fallbackLocale: 'en',
    });
};

// Localization
export const Localization = {
    locales: {},
    init: function(config) {
        this.currentLocale = config.currentLocale || config.fallbackLocale;
        this.fallbackLocale = config.fallbackLocale;
        this.locales = config.dictionary;
    },
    setLocale: function(locale) {
        this.currentLocale = locale;
    },
    getTranslation: function(key) {
        return (
            this.locales[this.currentLocale][key] ||
            this.locales[this.fallbackLocale][key]
        );
    },
};

// Translation
export const t = function(key, mapping) {
    if (key) {
        key = key.toLowerCase();
    }
    if (!mapping) {
        return Localization.getTranslation(key);
    } else {
        let translation = Localization.getTranslation(key);
        // replace mapping values
        Object.keys(mapping).forEach(key => {
            translation = translation.replace('{' + key + '}', mapping[key]);
        });
        return translation;
    }
};
