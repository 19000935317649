import { useCallback, useState } from 'react';

const useHeight = () => {

    const [height, setHeight] = useState();

    // get height of the list
    // screens can never change size, hence this "save once" idea
    const heightRef = useCallback(node => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height);
        }
    }, []);

    return [heightRef, height];
};

export default useHeight;
