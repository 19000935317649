import { t } from '../../../core/localization';
import React from 'react';
import moment from 'moment';

const ReservationInfo = ({ current }) => {
    if (!current) {
        return (
            <div className="reservation-content">
                <h3 className="reservation-content__title">
                    {t('reservations.next.empty')}
                </h3>
            </div>
        );
    }

    return (
        <div className="reservation-content">
            {current.logo && (
                <img
                    src={current.logo}
                    className="reservation-content__img"
                    alt={current.customer.company}
                />
            )}
            <p className="reservation-content__time">
                {moment(current.from).format('HH:mm')}
                <span>→</span>
                {moment(current.to).format('HH:mm')}
            </p>
            <h3 className="reservation-content__title">{current.subject}</h3>
            {current.announce && current.announce.length > 0 && (
                <p className="reservation-content__announce">
                    {t('reservations.next.announce')}
                    {current.announce.join(', ')}
                </p>
            )}
        </div>
    );
};

export default ReservationInfo;
