export default {
    'app.name': 'MyTurtle v3',

    'error.no_connection.title': 'Geen connectie',
    'error.no_connection.description': 'Opnieuw verbinden',

    'reservations.next.title': 'Eerstkomende',
    'reservations.next.empty': 'Geen reservatie',
    'reservations.next.announce': 'Met: ',
    'reservations.schedule.title': 'Komende',
    'reservations.schedule.empty': 'Geen komende reservaties vandaag',

    'weather.temperature': 'Temperatuur',
    'weather.wind': 'Windsnelheid',
    'weather.humidity': 'Vochtigheid',
};
