import React from 'react';
import {
    getPrimaryColor,
    getTextColor,
} from '../../core/modules/screen/selectors';

const App = ({ config, children }) => {
    return (
        <div
            className="app"
            style={{
                '--primary-color': config ? getPrimaryColor(config) : '',
                '--text-color': config ? getTextColor(config) : '#FFFFFF',
            }}>
            {children}
        </div>
    );
};

export default App;
