import React from 'react';
import TurtleGrid from '../turtle/TurtleGrid';
import { getFooterScreens } from '../../core/modules/screen/selectors';
import { useIndex } from '../../core/hooks/useIndex';

const Footer = ({ config }) => {
    const footer = getFooterScreens(config);
    const footerIndex = useIndex(footer);

    return (
        <footer
            className={`footer ${
                footer[footerIndex].inverted ? 'footer--inverted' : ''
            }`}>
            {footer.map((screen, i) => (
                <TurtleGrid
                    key={`grid_${i}`}
                    className="footer__turtle-grid"
                    active={i === footerIndex}
                    screen={screen}
                />
            ))}
        </footer>
    );
};

export default Footer;
