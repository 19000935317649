import { isVoid } from '../utils/isVoid';

const formatKey = key => {
    return `config_cache_${key}`;
};

const saveConfig = (key, config) => {
    localStorage.setItem(formatKey(key), JSON.stringify(config));
};

const hasConfig = key => {
    return !isVoid(getConfig(key));
};

const getConfig = key => {
    return !isVoid(localStorage.getItem(formatKey(key)))
        ? JSON.parse(localStorage.getItem(formatKey(key)))
        : null;
};

export const LocalStorage = { saveConfig, hasConfig, getConfig };
