import React from 'react';
import Logo from 'assets/images/logo-lg.svg';

const ErrorScreen = ({ error }) => {
    return (
        <div className="error-screen">
            <img src={Logo} alt="FlatTurtle" />

            <p className="error-screen__message">{error}</p>
        </div>
    );
};

export default ErrorScreen;
