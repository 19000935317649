import { useEffect, useState } from 'react';
import axios from 'axios';
import { ConnectionStatus } from '../modules/connection/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setConnectionStatus } from '../modules/connection/reducer';
import * as Sentry from '@sentry/browser';

export const useConnection = () => {
    const dispatch = useDispatch();
    const { status } = useSelector(state => state.connection);
    const { uid } = useSelector(state => state.screen);

    const [retryCount, setRetryCount] = useState(1);

    // retry ping every few seconds with a max of 60 * 1000
    useEffect(() => {
        if (
            status === ConnectionStatus.OFFLINE ||
            status === ConnectionStatus.CHECKING
        ) {
            const id = setTimeout(() => {
                axios
                    .get(process.env.REACT_APP_CONNECTION_PING_URL)
                    .then(() => {
                        if (status === ConnectionStatus.CHECKING) {
                            // we're online
                            dispatch(
                                setConnectionStatus(ConnectionStatus.ONLINE)
                            );
                            // let Sentry know
                            Sentry.captureException(
                                `Server or API route down for ${uid}`
                            );
                        } else {
                            // reload page
                            window.location.reload();
                        }
                    })
                    .catch(error => {
                        if (status === ConnectionStatus.CHECKING) {
                            dispatch(
                                setConnectionStatus(ConnectionStatus.OFFLINE)
                            );
                        } else {
                            setRetryCount(retryCount + 1);
                        }
                    });
            }, Math.min(retryCount * 5000, 60 * 1000));
            return () => clearTimeout(id);
        }
    }, [status, retryCount, dispatch, uid]);

    return null;
};
