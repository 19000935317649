import { t } from '../../../core/localization';
import React from 'react';
import moment from 'moment';

const ReservationList = ({ list }) => {
    if (list.length === 0) {
        return (
            <p className="reservation-empty-schedule">
                {t('reservations.schedule.empty')}
            </p>
        );
    }

    return (
        <ul className="reservation-schedule">
            {list.map(item => (
                <li key={item.id} className="reservation-item">
                    <span className="reservation-item__time">
                        {moment(item.from).format('HH:mm')} -{' '}
                        {moment(item.to).format('HH:mm')}
                    </span>
                    <span className="reservation-item__label">
                        {item.subject}
                    </span>
                </li>
            ))}
        </ul>
    );
};

export default ReservationList;
